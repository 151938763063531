const PortHeader = () => {
  return (
    <div id="port-header">
      <h1>My Portfolio</h1>
      <h3>Notable Work</h3>
    </div>
  );
};

export default PortHeader;
