/*eslint-disable*/

import Me from "../img/PaulCropped.webp";
import Mob from "../img/mobile_ui.gif";
import resume from "../img/PaulVera_Resume.pdf";
import cbc from "../img/cbc.webp";
import circulate from "../img/circulate.webp";
import gower from "../img/gower.webp";
import inspire from "../img/inspire.webp";
import inspired from "../img/inspired.webp";
import tfww from "../img/tfww.webp";
import dlp from "../img/DLP.webp";

export default {
  Me,
  Mob,
  resume,
  cbc,
  circulate,
  gower,
  inspire,
  inspired,
  tfww,
  dlp,
};
